<template>
	<v-container fluid>
		<div class="d-flex flex-wrap py-2">
			<v-btn class="ma-1" color="primary" small @click="getPosiblesColaboradores">
				<v-icon left>mdi-reload</v-icon>Recargar
			</v-btn>
			<v-spacer />
			<v-btn class="ma-1" color="primary" small @click.stop="exportarTodos">
				<v-icon left>mdi-download</v-icon>Exportar todos
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="posiblesColaboradores"
				:headers="headers"
				item-key="idPosibleColaborador"
				:calculate-widths="true"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
				:single-select="false"
				show-select
				v-model="selected"
				checkbox-color="secondary"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="posiblesColaboradores" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.fechaRegistro="{ item }">
					<span v-text="parseDate(item.fechaRegistro, false, true, true)"></span>
				</template>

				<template v-slot:item.imagen="{ item }">
					<v-btn icon color="primary" @click="detailsId = item.idPosibleColaborador">
						<v-icon>mdi-camera-image</v-icon>
					</v-btn>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			:value="detailsId"
			@input="(v) => v === false ? detailsId = null : null"
			max-width="1200px"
		>
			<v-card class="pa-2 d-flex flex-wrap align-start" :loading="imageURL.length == 0">
				<v-hover v-for="(img, index) in imageURL" :key="index" v-slot="{ hover }">
					<v-sheet class="pa-2 hover">
						<v-img max-width="400px" contain :src="img" />
						<div :class="{ 'opacity-0': !hover }">
							<v-btn fab small @click="download(img)">
								<v-icon color="primary">mdi-download</v-icon>
							</v-btn>
						</div>
					</v-sheet>
				</v-hover>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { perColumnFilter, parseDate, downloadFile, decode64 } from '@/utils/index.js';
import axios from 'axios'

export default {
	components: {
		TableFilters: () => import('@/components/TableFilters.vue'),
	},
	data() {
		return {
			loading: false,
			inlineFilters: {},

			posiblesColaboradores: [],
			selected: [],

			headers: [
				// { text: 'DNI', value: 'datos.dni' },
				// { text: 'Nombre', value: 'datos.nombre' },
				// { text: 'Apellido 1', value: 'datos.apellido1' },
				// { text: 'Apellido 2', value: 'datos.apellido2' },
				{ text: 'Empresa', value: 'datos.empresa' },
				{ text: 'Per. Contacto', value: 'datos.personaContacto' },
				{ text: 'Correo', value: 'datos.email' },
				{ text: 'Teléfono', value: 'datos.telefono' },
				{ text: 'Ciudad', value: 'datos.ciudad' },
				{ text: 'Cod.Postal', value: 'datos.codPostal' },
				{ text: 'Provincia', value: 'datos.provincia' },
				{ text: 'Fecha registro', value: 'fechaRegistro', dataType: 'date' },
				{ text: 'Comentario', value: 'datos.comentario' },
				{ text: 'Origen', value: 'origen', dataType: 'select' },
				{ text: 'Imagen', value: 'imagen', sortable: false, filterable: false },
			].map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			detailsId: null,
			imageURL: [],
		}
	},
	mounted() {
		this.getPosiblesColaboradores();
	},
	methods: {
		parseDate,
		getPosiblesColaboradores() {
			this.loading = true;
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/posiblesColaboradoresFeria.php`,
				method: 'GET',
				params: {
					token: this.$store.getters.getToken
				}
			}).then(res => {
				this.posiblesColaboradores = res.data.map(c => ({ ...c, datos: JSON.parse(c.datos) }))
			}).catch(err => {
				console.error(err);
				this.$root.$emit('snack', 'Ha ocurrido un error al cargar los datos');
			}).finally(() => {
				this.loading = false
			})
		},
		exportarTodos() {
			const getDatatype = (key) =>
				(this.headers.find((h) => h.value == key) || {}).dataType;

			this.selected = this.posiblesColaboradores.filter((con) =>
				Object.entries(con).every(([key, value]) =>
					perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
				)
			);

			const csvHeaders = [
				{ key: 'datos.dni' },
				{ key: 'datos.nombre' },
				{ key: 'datos.apellido1' },
				{ key: 'datos.apellido2' },
				{ key: 'datos.email' },
				{ key: 'datos.telefono' },
				{ key: 'datos.ciudad' },
				{ key: 'datos.codPostal' },
				{ key: 'datos.provincia' },
				{ key: 'fechaRegistro', type: 'date' },
				{ key: 'datos.comentario' },
				{ key: 'origen' },
			];

			const JStoExcelDate = (date) => {
				const [day, month, year] = date.split(' ')
				return [year, month, day].join('-')
			}

			const resolve = (path, obj = this, separator = '.') => {
				const properties = Array.isArray(path) ? path : path.split(separator)
				return properties.reduce((prev, curr) => prev && prev[curr], obj)
			}

			const csvString = [
				...this.selected.map(item => {
					let temp = [];
					csvHeaders.forEach(({ key, type }) => {
						let valor = (resolve(key, item) || '\u0020').replaceAll(/(\r)|(\n)/g, '');
						if (type == 'date') valor = JStoExcelDate(parseDate(valor, false, false));
						if (type == 'number') valor = valor.replace('.', ',');
						temp.push(valor);
					})
					return temp;
				})
			];

			const csvFile = new File(['\ufeff', [csvHeaders.map(h => h.key.split('.')[h.key.split('.').length - 1]), ...csvString].map(e => e.join(";")).join("\n")], `POSIBLES_COLABORADORES_${parseDate(new Date(), false, true)}.csv`, { type: 'text/csv;charset=utf-8;' });

			downloadFile(csvFile);

			this.$nextTick(() => {
				this.selected = [];
			});
		},
		async download(img) {
			const file = await fetch(img).then(res => res.blob())
			downloadFile(new File([file], '', { type: 'image/png' }), true)
		}
	},
	watch: {
		async detailsId(v) {
			if (v) {
				this.imageURL = [];
				const { data } = await axios({
					url: `${process.env.VUE_APP_OUR_API_URL}/posiblesColaboradoresFeria.php`,
					method: 'GET',
					params: {
						idPosibleColaborador: v,
						img: true,
						token: this.$store.getters.getToken,
					}
				})
				for (const img of data) {
					this.imageURL.push(img)
				}
				// this.imageURL = URL.createObjectURL(data);
			} else {
				this.imageURL = null
			}
		}
	}
}
</script>

<style>
.hover:hover {
	background: #ececec;
}
.opacity-0 {
	opacity: 0;
}
</style>