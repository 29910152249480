var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-wrap py-2"},[_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":_vm.getPosiblesColaboradores}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-reload")]),_vm._v("Recargar ")],1),_c('v-spacer'),_c('v-btn',{staticClass:"ma-1",attrs:{"color":"primary","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.exportarTodos.apply(null, arguments)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-download")]),_vm._v("Exportar todos ")],1)],1),_c('v-card',[_c('v-data-table',{attrs:{"fixed-header":"","items":_vm.posiblesColaboradores,"headers":_vm.headers,"item-key":"idPosibleColaborador","calculate-widths":true,"loading":_vm.loading,"footer-props":{
				showFirstLastPage: true,
				showCurrentPage: true,
				itemsPerPageOptions: [10, 25, 50],
			},"single-select":false,"show-select":"","checkbox-color":"secondary"},scopedSlots:_vm._u([{key:"body.prepend",fn:function(ref){
			var headers = ref.headers;
return [_c('table-filters',{attrs:{"headers":headers,"items":_vm.posiblesColaboradores},model:{value:(_vm.inlineFilters),callback:function ($$v) {_vm.inlineFilters=$$v},expression:"inlineFilters"}})]}},{key:"item.fechaRegistro",fn:function(ref){
			var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.parseDate(item.fechaRegistro, false, true, true))}})]}},{key:"item.imagen",fn:function(ref){
			var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","color":"primary"},on:{"click":function($event){_vm.detailsId = item.idPosibleColaborador}}},[_c('v-icon',[_vm._v("mdi-camera-image")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('v-dialog',{attrs:{"value":_vm.detailsId,"max-width":"1200px"},on:{"input":function (v) { return v === false ? _vm.detailsId = null : null; }}},[_c('v-card',{staticClass:"pa-2 d-flex flex-wrap align-start",attrs:{"loading":_vm.imageURL.length == 0}},_vm._l((_vm.imageURL),function(img,index){return _c('v-hover',{key:index,scopedSlots:_vm._u([{key:"default",fn:function(ref){
			var hover = ref.hover;
return [_c('v-sheet',{staticClass:"pa-2 hover"},[_c('v-img',{attrs:{"max-width":"400px","contain":"","src":img}}),_c('div',{class:{ 'opacity-0': !hover }},[_c('v-btn',{attrs:{"fab":"","small":""},on:{"click":function($event){return _vm.download(img)}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-download")])],1)],1)],1)]}}],null,true)})}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }